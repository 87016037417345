<template>
    <el-container>
      <el-row style="width: 100%">
        <el-col :span="24">
          <div class="cardTitle">满意度调查列表</div>
          <el-button type="primary" @click="createSurvey" class="cardTitleBtn">添加项目</el-button
          >
        </el-col>
        <el-col :span="24" class="card_style">
          <el-table :data="surveyList" style="width: 100%">
            <el-table-column sortable prop="name" label="项目名称"></el-table-column>
            <el-table-column sortable prop="clientName" label="委托方名称"></el-table-column>
            <el-table-column sortable prop="remark" label="备注"></el-table-column>
            <el-table-column sortable prop="updateTime" label="最近更新时间"></el-table-column>
            <el-table-column sortable label="操作" width="300px">
              <template slot-scope="scope">
                <el-button type="primary" size="small" @click="getDetail(scope.row)" v-if="userInfo.role ==1">基础信息</el-button>
                <el-button type="primary" size="small" @click="getQuestion(scope.row.guid)">详情</el-button>
                <el-button type="danger" size="small" @click="onDelete(scope.row.guid, scope.row.name)" v-if="userInfo.role ==1">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
        <el-col :span="24">
          <div class="block" style="margin-top: 20px">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="recordList.nowPageNum"
              :page-sizes="[ 20, 40, 80]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="recordList.totalNum"
            >
            </el-pagination>
          </div>
        </el-col>
      </el-row>

      <el-dialog title="添加项目" :visible.sync="dialogVisible" width="50%">
        <el-form :model="itemForm" ref="itemForm" label-width="130px">
          <el-form-item label="项目名称" prop="name">
            <el-input v-model="itemForm.name"></el-input>
          </el-form-item>
          <el-form-item label="委托方名称" prop="clientName">
            <el-input v-model="itemForm.clientName"></el-input>
          </el-form-item>
          <el-form-item label="委托方电话" prop="clientPhone">
            <el-input v-model="itemForm.clientPhone"></el-input>
          </el-form-item>
          <el-form-item label="委托方联系人名称" prop="contactName">
            <el-input v-model="itemForm.contactName"></el-input>
          </el-form-item>
          <el-form-item label="委托方联系人电话" prop="contactPhone">
            <el-input v-model="itemForm.contactPhone"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input v-model="itemForm.remark" type="textarea" placeholder="请输入内容"></el-input>
          </el-form-item>
          <el-form-item label="问卷说明" prop="surveyContent">
            <el-row>
              <el-col :span="20">
                <quill-editor v-model="itemForm.surveyContent" :options="editorOption"></quill-editor>
              </el-col>
              <el-col :span="4">
                <el-image :src="'https://survey-platform-test.tbs-datum.com/file/desc.png'" style="width: 100px;height:100px" :preview-src-list="['https://survey-platform-test.tbs-datum.com/file/desc.png']" :z-index="zIndex"></el-image>
              </el-col>
            </el-row>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveOrUpdate">确 定</el-button>
        </span>
      </el-dialog>

      <el-dialog title="基础信息" :visible.sync="basicsVisible" width="50%">
        <el-form :model="itemForm" ref="itemForm" label-width="130px">
          <el-form-item label="项目名称" prop="name">
            <el-input v-model="itemForm.name"></el-input>
          </el-form-item>
          <el-form-item label="委托方名称:" prop="clientName">
            <el-input v-model="itemForm.clientName"></el-input>
          </el-form-item>
          <el-form-item label="委托方电话" prop="clientPhone">
            <el-input v-model="itemForm.clientPhone"></el-input>
          </el-form-item>
          <el-form-item label="委托方联系人名称" prop="contactName">
            <el-input v-model="itemForm.contactName"></el-input>
          </el-form-item>
          <el-form-item label="委托方联系人电话" prop="contactPhone">
            <el-input v-model="itemForm.contactPhone"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input v-model="itemForm.remark" type="textarea" placeholder="请输入内容"></el-input>
          </el-form-item>
          <el-form-item label="问卷说明" prop="surveyContent">
            <el-row>
              <el-col :span="20">
                <quill-editor v-model="itemForm.surveyContent" :options="editorOption"></quill-editor>
              </el-col>
              <el-col :span="4">
                <el-image :src="'https://survey-platform-test.tbs-datum.com/file/desc.png'" style="width: 100px;height:100px" :preview-src-list="['https://survey-platform-test.tbs-datum.com/file/desc.png']" :z-index="zIndex"></el-image>
              </el-col>
            </el-row>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="basicsVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveOrUpdate">确 定</el-button>
        </span>
      </el-dialog>
    </el-container>
</template>

<script>
export default {
    data() {
        return {
            surveyList: [],
            pageSize: 20,
            dialogVisible: false,
            basicsVisible: false,
            itemForm: {
              clientName: "",
              clientPhone: "",
              contactName: "",
              contactPhone: "",
              guid: "",
              name: "",
              remark: "",
              surveyContent: ""
            },
            editorOption: {
              placeholder: '请输入正文......',
              modules: {
                toolbar: [
                  ['bold', 'italic', 'underline', 'strike'],
                  [{ 'indent': '-1'}, { 'indent': '+1' }],
                  [
                    { 'list': 'ordered'},
                    { 'list': 'bullet'}
                  ],
                  [
                    { 'color': [] }
                  ],
                  [
                    { 'align': [] }
                  ]
                ]
              }
            },
            recordList: {},
            imgUrl: ['../../assets/examples.png'],
            zIndex: 9999
        }
    },
    mounted() {
      let self = this;
      self.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      self.getSurveyList(1);
    //   self.getProvinceList();
    //   self.getAllCommunityList();
    },
    methods: {
      getSurveyList(pageNum) {
          let self = this
          self.$axios.get("/survey-project/page?pageSize=" + self.pageSize +"&pageNum=" +(pageNum || 1),
              {
                  headers: { token: self.userInfo.token },
              }
          )
          .then(function (response) {
              self.surveyList = response.data.data.records;
              self.recordList = response.data.data
              console.log(self.surveyList);
          })
          .catch(function (error) {
              self.$message({
              message: "满意度调查列表获取失败：" + error.response.data.message,
              type: "warning",
              });
          });
      },
      createSurvey() {
        let self = this
        self.dialogVisible = true    
        self.itemForm = {
            clientName: "",
            clientPhone: "",
            contactName: "",
            contactPhone: "",
            guid: "",
            name: "",
            remark: "",
            surveyContent: ""
        }
      },
      saveOrUpdate() {
        let self = this
        self.$axios.post("/survey-project/saveOrUpdate", self.itemForm, {
            headers: { token: self.userInfo.token },
          }
        )
        .then(function(res) {
          self.$message({
            message: "操作成功",
            type: "success",
          })
          if (res.data.status) {
            self.getSurveyList(1)
            self.dialogVisible = false
            self.basicsVisible = false
          }
        }
        )
        .catch(function (error) {
          self.$message({
            message: "保存失败" + error.response.data.message,
            type: "warning",
          });
        });
      },
      getDetail(item) {
        console.log(item);
        let self = this
        self.itemForm.clientName = item.clientName,
        self.itemForm.clientPhone = item.clientPhone,
        self.itemForm.contactName = item.contactName,
        self.itemForm.contactPhone = item.contactName,
        self.itemForm.guid = item.guid,
        self.itemForm.name = item.name,
        self.itemForm.remark = item.remark,
        self.itemForm.surveyContent = item.surveyContent
        self.basicsVisible = true
      },
      getQuestion(guid) {
        console.log(guid);
        let self = this;
        self.$router.push({
          name: 'ProjectQuestion',
          query: { guid: guid },
        });
      },
      onDelete(guid, name) {
        console.log(guid,name);
        let self = this;
        self
          .$confirm("确定删除" + name + "吗？")
          .then(() => {
            self.$axios
              .delete("/survey-project/delete", {
                data: { guid: guid },
                headers: { token: self.userInfo.token },
              })
              .then(function () {
                self.$message({
                  message: "删除成功",
                  type: "success",
                });
                self.getSurveyList(1);
              })
              .catch(function (error) {
                self.$message({
                  message: "删除失败" + error.response.data.message,
                  type: "warning",
                });
              });
          })
      },
      handleSizeChange(val) {
        this.pageSize = val * 1;
        this.getSurveyList()
      },
      handleCurrentChange(val) {
        this.getSurveyList(val)
      }
    }
}
</script>

<style>
  .moreBtn{
    float: right;
    color: #409EFF;
    cursor: pointer;
  }
  .cardBody{
    height: 60px;
    overflow: hidden;
    font-size: 14px;
    color: rgb(88, 88, 88);
  }
  .card_style {
    margin-top: 20px;
    padding-left: 40px;
  }
  .box-card {
    width: 100%;
  }
  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both;
  }
</style>